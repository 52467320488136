import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Italy",
  "subtitle": "January 2008",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Beware of pickpockets! Keep your purses and belongs secured with you and if you see a sign that warns about pickpockets, don't go looking for your wallet since you're just giving away the info.`}</li>
      <li parentName="ul">{`Bargain with street vendors. I haggled a fake painting from €25 to €3...`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Gelato"
        }}>{`Gelato`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Nutella"
        }}>{`Nutella`}</a>{` - On crepes!`}</li>
      <li parentName="ul">{`Hot Chocolate`}</li>
    </ul>
    <h2 {...{
      "id": "florence"
    }}>{`Florence`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HQgAgv96MbvAxgYH6"
        }}>{`Rosticceria Chinese Jian Jian`}</a>{` - Hah! This was my first meal studying abroad so it's just nostalgic to me. But also, the only place still opened as of 2024.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hC2ie4zwofCzioow6"
        }}>{`Piazzale Michelangelo`}</a>{` - Great panoramic views on top of the hill`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/R8yt84v5HpVS6crT8"
        }}>{`Galleria dell’Accademia`}</a>{` - The David is worth seeing; Security guard is watching to prevent photos. Not a very big gallery`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7JT8EbuqNvKPByYB6"
        }}>{`Brunelleschi’s dome`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Climb to the top for a view`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/oVXJqXakz7FUd4Jx6"
            }}>{`Cattedrale di Santa Maria del Fiore`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/JZLJshJYLCyHMgvc9"
            }}>{`Campanile di Giotto`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4jxpPYHka2yRhfLo9"
        }}>{`Piazza della Signoria`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Main square, hard to miss. There is a replica of the David here and many other marble sculptures.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/KK2TyNeEMfZFwNtS9"
            }}>{`Uffizi Gallery`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/URdoDGYvGN5poSXz6"
            }}>{`Loggia dei Lanzi`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nYWdARCDcZ6w8aHc6"
        }}>{`Ponte Vecchio`}</a>{` - Iconic bridge`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iFnE9xzgf16Aw5y49"
        }}>{`Basilica di Santa Croce di Firenze`}</a>{` - I passed by a lot along the way to the university and there were always events going on`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/J2XVLvGMv9mwd2Nt7"
        }}>{`Piazza della Repubblica`}</a>{` - There's a so-so merry-go-round here`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9yvLZjKoa9BFeJHdA"
        }}>{`Mercato Centrale`}</a>{` - Haggle if you shop`}</li>
    </ul>
    <h2 {...{
      "id": "venice"
    }}>{`Venice`}</h2>
    <h3 {...{
      "id": "shopping"
    }}>{`Shopping`}</h3>
    <ul>
      <li parentName="ul">{`Venetian Mask - The nicer ones look very different and are usually handmade, fitted and go for €1000+`}</li>
      <li parentName="ul">{`Murano Glass - Manufactured on a small island close to Venice.`}</li>
      <li parentName="ul">{`Wooden Toys`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NxPDVSxiCkixYkeS9"
        }}>{`Basilica di San Marco`}</a>{` - The basilica is nice. I read it's illegal to feed the pigeons now.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hyR16Y6vqtH37Gnh6"
        }}>{`Gondola ride`}</a>{` - When I was here, they had boats on the side canals that went for a cheaper rate. It was flat rate per boat.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4NJykWvgsxruNdxN9"
        }}>{`Doge’s Palace`}</a>{` - I didn’t get to go inside`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/xK6BNPdEMxyU5Yf38"
        }}>{`Rialto Bridge`}</a>{` - Iconic bridge`}</li>
    </ul>
    <h2 {...{
      "id": "rome"
    }}>{`Rome`}</h2>
    <ul>
      <li parentName="ul">{`Saw Pope Benedict XVI`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul">{`Alla Rampa - Food was so-so. It’s here for my memory purposes.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/M96Q67XMq6DXGWhb8"
        }}>{`Colosseum`}</a>{` - Much cooler in person!`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/CHiVH6HuRwhDf5oE7"
            }}>{`Roman Forum`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Ydb2SvXAj7cDW79A9"
            }}>{`Arch of Titus`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/km9SrGvHGZnUTtZWA"
        }}>{`Fontana di Trevi`}</a>{` - The Fontana’s wish is to toss a coin backwards so you can return to Rome one day. I threw one in but haven’t been back haha. It's very crowded here.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dbSoTvFPdQEXADjo7"
        }}>{`Pantheon`}</a>{` - Temple was much bigger than I thought`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/bUSQN5NBCHmMhrBZ9"
        }}>{`Piazza Navona`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/SfKDbxfQmgyVC1gF9"
        }}>{`Spanish Steps`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Looks cool at night`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/2KdhRp5TTzJoh6bJ9"
            }}>{`Piazza di Spagna`}</a>{` - At the bottom of the steps`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VLTY9bLG2F1gb25DA"
        }}>{`Castel Sant’Angel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/C7vsUaGCaegm6zcP7"
        }}>{`Ponte Sant’Angel`}</a></li>
    </ul>
    <h3 {...{
      "id": "vatican-city"
    }}>{`Vatican City`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/A4yiHp5Prh22CVYx8"
        }}>{`Sistine Chapel`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Absolutely worth the visit!`}</li>
          <li parentName="ul">{`Highly recommend a guided tour for context`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/X7Wib2DZtJGLJMwq5"
        }}>{`St. Peter’s Basilica`}</a>{` - Don’t forget to climb the Cupola (Dome) for the view`}</li>
    </ul>
    <h2 {...{
      "id": "pisa"
    }}>{`Pisa`}</h2>
    <ul>
      <li parentName="ul">{`I took the train from Florence for a day trip`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/HPDVAe5MSoTmafTg6"
        }}>{`Leaning Tower of Pisa`}</a>{` - Don't forget the silly photos!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/V2uUqyxXmm2b6Nwn8/"
        }}>{`Cattedrale di Pisa`}</a></li>
    </ul>
    <h2 {...{
      "id": "prato"
    }}>{`Prato`}</h2>
    <ul>
      <li parentName="ul">{`I took the train from Florence for a day trip. My art professor at the time said that this city was like the Chinatown of Italy. Interesting `}<a parentName="li" {...{
          "href": "http://www.nytimes.com/2010/09/13/world/europe/13prato.html"
        }}>{`NYT article`}</a>{` back in 2010 about the fashion industry.`}</li>
      <li parentName="ul">{`Lots of interesting graffiti`}</li>
    </ul>
    <h2 {...{
      "id": "siena"
    }}>{`Siena`}</h2>
    <ul>
      <li parentName="ul">{`I took the train from Florence for a day trip but it felt rushed`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sB9nPg5VF2F5MHp27"
        }}>{`Piazza del Campo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/izehBCqPStukgAEs9"
        }}>{`Torre del Mangia`}</a>{` - Famous tower that took a decade to build. It’s very tall, if not the tallest thing in town so it’s hard to miss. Use this as a guiding reference if you're lost in a random alleyway.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      